import React from 'react';
import QRCode from 'qrcode.react';

const StaticQRCode = () => {
  const staticUrl = 'https://amlbot-x.com/connect?action=trusttron';

  return (
    <div style={{ textAlign: 'center', marginTop: '20px' }}>
      <h2>Scan this QR Code with your WalletConnect-compatible wallet</h2>
      <QRCode value={staticUrl} size={256} />
    </div>
  );
};

export default StaticQRCode;